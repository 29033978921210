<template>
  <div class="cooling" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Cooling"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000">{{ $t('cooling-title') }}</h1>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('cooling-section1-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('cooling-section1-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div id="scroll-container">
        <div id="disco-on">
          <img :src="images[1]" alt="CarboBrake Cool system"/>
          <div id="disco-separator"></div>
        </div>
        <div id="disco-off">
          <img :src="images[2]" alt="CarboBrake cool system"/>
        </div>
      </div>
    </div>

    <div class="section">
      <div
        class="section-title full"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('cooling-section2-title')"
      ></div>
      <div
        class="section-paragraph full"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('cooling-section2-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000"></div>
      <div class="section-title side" data-aos="fade-right" data-aos-duration="1000">
        <span v-html="$t('cooling-section3-title')"></span>
        <img :src="images[3]" alt="CarboBrake Cooling"/>
      </div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('cooling-section3-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-title side" data-aos="fade-right" data-aos-duration="1000">
        <span v-html="$t('cooling-section4-title')"></span> <img :src="images[4]" alt="CarboBrake Race Cooling"/>
      </div>

      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('cooling-section4-paragraph')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('../assets/cooling_cover.jpg'),
        require('../assets/cool_system_sicom_step1.jpg'),
        require('../assets/cool_system_sicom_step2.jpg'),
        require('../assets/sds_1690-1.jpg'),
        require('../assets/sds_1674-1.jpg')
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          //simulate resize for scrolling transitions
          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)

        //animazioni della pagina
        //SCROLLMAGIC
        // Declare Scene
        const sceneCooling = this.$scrollmagic
          .scene({
            // ID of element where animation starts
            triggerElement: '#scroll-container',

            // {0,0.5,1} - animations starts from {top,center,end} of window
            triggerHook: 0,

            // Duration of animation
            duration: '120%',
            offset: -150
          })

          // Declaration of animation and attaching to element
          .setTween('#disco-on', {
            css: {
              height: 0 // the tween duration can be omitted and defaults to 1
            }
          })
          // Helpful tags for orientation on the screen
          //.addIndicators({ name: 'cooling (duration: 300)' })
          .setPin('#scroll-container')

        // add scenes to window scrollmagic controller
        this.$scrollmagic.addScene(sceneCooling)
      })
    })
  }
}
</script>

<style lang="scss">
#scroll-container {
  position: relative;
  top: 0;
  left: 0;
  bottom: auto;
  right: auto;
  box-sizing: border-box;
  width: 100%;
  height: 619px;
  min-height: 619px;
  margin: 200px 0;

  #disco-off,
  #disco-on {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
  }

  #disco-off img,
  #disco-on img {
    max-width: 100%;
  }

  #disco-on {
    z-index: 100;
  }
  #disco-off {
    z-index: 50;
  }
  #disco-separator {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      to right,
      rgba(109, 109, 109, 0) 0,
      rgba(109, 109, 109, 0) 22%,
      #6d6d6d 50%,
      rgba(109, 109, 109, 0) 78%,
      rgba(109, 109, 109, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006d6d6d', endColorstr='#006d6d6d', GradientType=1 );
  }
}
</style>
